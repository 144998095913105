.shirts {
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0,0,0, 0.1);
    /* padding: 10%; */
}

.shirts:before {
    content: '';
    position: absolute;
    /* background: url('../../assets/ukr-view_7_2600-1600_8bit.png')no-repeat center center/cover; */
    background: url('../../assets/Kulian_poster.jpg')no-repeat center center/cover;
    height: 100%;
    width: 100%;   
    z-index: -1;
    right: 50%;
    width: 50%;
    /* padding: 10%; */
}

.shirts .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 30%;
    width: 50%;
    padding: 10%;
}

/* .shirts .content p {
    margin: 1rem 0;
} */

@media screen and (max-width: 800px) {
    .shirts .content {
        left: 0;
        width: 100%;
        padding: 2rem;
        background: rgba(255,255,255, 0.8);
    }
    
    .shirts:before{
        display: none;
    }

    .shirts {
        width: 100%;
        height: 100vh;
        position: relative;
        /* background: rgba(0,0,0, 0.1); */
        background: url('../../assets/ukr-view_7_2600-1600_8bit.png')no-repeat center center/cover;
        /* padding: 10%; */
    }

}